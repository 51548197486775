export const contact = [
    {
        designation: 'phone',
        label: 'Telefon',
        value: '450 001 550',
        href: 'tel:+48450001550'
    },
    {
        designation: 'phone',
        label: 'Telefon',
        value: '539 968 541',
        href: 'tel:+48539968541'
    },
    {
        designation: 'email',
        label: 'E-mail',
        value: 'info@drninawisniewska.pl',
        href: 'mailto:info@drninawisniewska.pl'
    },
    {
        designation: 'address',
        label: 'Adres',
        value: 'ul. Fryderyka Chopina 13/U2A, 07-200 Wyszków',
        href: 'https://goo.gl/maps/1a3qFdxNqTNJdahY9'
    },
    {
        designation: 'hours',
        label: 'Godziny otwarcia',
        value: 'od poniedziałku do piątku: 10:00 - 18:00',
        value2: 'w sobotę: 10:00 - 15:00'
    }
];
