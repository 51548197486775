// DERMATOLOGIA
// badanie znamion barwnikowych
import badanieZnamionBarwnikowychImage0 from '../assets/treatment/dermatology/badanieZnamionBarwnikowych/badanieZnamionBarwnikowych0.jpg';

// fototerapia chorób skóry
import fototerapiaChorobSkoryImage0 from '../assets/treatment/dermatology/fototerapiaChorobSkory/fototerapiaChorobSkory0.jpg';

// usunięcie zmian skórnych elektrochirurgiczne
import usuniecieZmianSkornychElektrochirurgiczneImage0 from '../assets/treatment/dermatology/usuniecieZmianSkornychElektrochirurgiczne/usuniecieZmianSkornychElektrochirurgiczne0.jpg';

// MEDYCYNA ESTETYCZNA
// modelowanie ust
import modelowanieUstImage0 from '../assets/treatment/aestheticMedicine/modelowanieUst/modelowanieUst0.jpg';
import effectsModelowanieUst0Lg from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst0Lg.png';
import effectsModelowanieUst0Sm from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst0Sm.png';
import effectsModelowanieUst1Lg from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst1Lg.png';
import effectsModelowanieUst1Sm from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst1Sm.png';
import effectsModelowanieUst2Lg from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst2Lg.png';
import effectsModelowanieUst2Sm from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst2Sm.png';
import effectsModelowanieUst3Lg from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst3Lg.png';
import effectsModelowanieUst3Sm from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst3Sm.png';
import effectsModelowanieUst4Lg from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst4Lg.png';
import effectsModelowanieUst4Sm from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst4Sm.png';
import effectsModelowanieUst5Lg from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst5Lg.png';
import effectsModelowanieUst5Sm from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst5Sm.png';
import effectsModelowanieUst6Lg from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst6Lg.png';
import effectsModelowanieUst6Sm from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst6Sm.png';
import effectsModelowanieUst7Lg from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst7Lg.jpg';
import effectsModelowanieUst7Sm from '../assets/treatment/aestheticMedicine/modelowanieUst/effects/effectsModelowanieUst7Sm.jpg';

// toksyna botulinowa
import toksynaBotulinowaImage0 from '../assets/treatment/aestheticMedicine/toksynaBotulinowa/toksynaBotulinowa0.jpg';
import effectsToksynaBotulinowa0Lg from '../assets/treatment/aestheticMedicine/toksynaBotulinowa/effects/effectsToksynaBotulinowa0Lg.png';
import effectsToksynaBotulinowa0Sm from '../assets/treatment/aestheticMedicine/toksynaBotulinowa/effects/effectsToksynaBotulinowa0Sm.png';
import effectsToksynaBotulinowa1Lg from '../assets/treatment/aestheticMedicine/toksynaBotulinowa/effects/effectsToksynaBotulinowa1Lg.png';
import effectsToksynaBotulinowa1Sm from '../assets/treatment/aestheticMedicine/toksynaBotulinowa/effects/effectsToksynaBotulinowa1Sm.png';
import effectsToksynaBotulinowa2Lg from '../assets/treatment/aestheticMedicine/toksynaBotulinowa/effects/effectsToksynaBotulinowa2Lg.png';
import effectsToksynaBotulinowa2Sm from '../assets/treatment/aestheticMedicine/toksynaBotulinowa/effects/effectsToksynaBotulinowa2Sm.png';

// stymulatory tkankowe
import stymulatoryTkankoweImage0 from '../assets/treatment/aestheticMedicine/stymulatoryTkankowe/stymulatoryTkankowe0.jpg';

// stymulatory tkankowe
import osoczeBogatoplytkoweImage0 from '../assets/treatment/aestheticMedicine/osoczeBogatoplytkowe/osoczeBogatoplytkowe0.jpg';

// laser frakcyjny
import laserFrakcyjnyImage0 from '../assets/treatment/aestheticMedicine/laserFrakcyjny/laserFrakcyjny0.jpg';
import effectsLaserFrakcyjny0Lg from '../assets/treatment/aestheticMedicine/laserFrakcyjny/effects/effectsLaserFrakcyjny0Lg.png';
import effectsLaserFrakcyjny0Sm from '../assets/treatment/aestheticMedicine/laserFrakcyjny/effects/effectsLaserFrakcyjny0Sm.png';
import effectsLaserFrakcyjny1Lg from '../assets/treatment/aestheticMedicine/laserFrakcyjny/effects/effectsLaserFrakcyjny1Lg.png';
import effectsLaserFrakcyjny1Sm from '../assets/treatment/aestheticMedicine/laserFrakcyjny/effects/effectsLaserFrakcyjny1Sm.png';

// nici rewitalizujące i liftingujące
import niciRewitalizujaceILiftingujaceImage0 from '../assets/treatment/aestheticMedicine/niciRewitalizujaceILiftingujace/niciRewitalizujaceILiftingujace0.jpg';

// mezoterapia igłowa
import mezoterapiaIglowaImage0 from '../assets/treatment/aestheticMedicine/mezoterapiaIglowa/mezoterapiaIglowa0.jpg';

// zamykanie naczynek i likwidacja przebarwień
import zamykanieNaczynekILikwidacjaPrzebarwienImage0 from '../assets/treatment/aestheticMedicine/zamykanieNaczynekILikwidacjaPrzebarwien/zamykanieNaczynekILikwidacjaPrzebarwien0.jpg';
import effectsZamykanieNaczynekILikwidacjaPrzebarwien0Lg from '../assets/treatment/aestheticMedicine/zamykanieNaczynekILikwidacjaPrzebarwien/effects/effectsZamykanieNaczynekILikwidacjaPrzebarwien0Lg.png';
import effectsZamykanieNaczynekILikwidacjaPrzebarwien0Sm from '../assets/treatment/aestheticMedicine/zamykanieNaczynekILikwidacjaPrzebarwien/effects/effectsZamykanieNaczynekILikwidacjaPrzebarwien0Sm.png';
import effectsZamykanieNaczynekILikwidacjaPrzebarwien1Sm from '../assets/treatment/aestheticMedicine/zamykanieNaczynekILikwidacjaPrzebarwien/effects/effectsZamykanieNaczynekILikwidacjaPrzebarwien1Sm.jpg';
import effectsZamykanieNaczynekILikwidacjaPrzebarwien1Lg from '../assets/treatment/aestheticMedicine/zamykanieNaczynekILikwidacjaPrzebarwien/effects/effectsZamykanieNaczynekILikwidacjaPrzebarwien1Lg.jpg';
import effectsZamykanieNaczynekILikwidacjaPrzebarwien2Sm from '../assets/treatment/aestheticMedicine/zamykanieNaczynekILikwidacjaPrzebarwien/effects/effectsZamykanieNaczynekILikwidacjaPrzebarwien2Sm.jpg';
import effectsZamykanieNaczynekILikwidacjaPrzebarwien2Lg from '../assets/treatment/aestheticMedicine/zamykanieNaczynekILikwidacjaPrzebarwien/effects/effectsZamykanieNaczynekILikwidacjaPrzebarwien2Lg.jpg';

// sonoqueen technologia hifu
import sonoQueenTechnologiaHifuImage0 from '../assets/treatment/aestheticMedicine/sonoQueenTechnologiaHifu/sonoQueenTechnologiaHifu0.jpg';


// KOSMETOLOGIA
// peeling medyczny
import peelingMedycznyImage0 from '../assets/treatment/cosmetology/peelingMedyczny/peelingMedyczny0.jpg';
import effectsPeelingMedyczny0Sm from '../assets/treatment/cosmetology/peelingMedyczny/effects/effectsPeelingMedyczny0Sm.png';
import effectsPeelingMedyczny0Lg from '../assets/treatment/cosmetology/peelingMedyczny/effects/effectsPeelingMedyczny0Lg.png';
// import effectsPeelingMedyczny1Sm from '../assets/treatment/cosmetology/peelingMedyczny/effects/effectsPeelingMedyczny1Sm.png';
// import effectsPeelingMedyczny1Lg from '../assets/treatment/cosmetology/peelingMedyczny/effects/effectsPeelingMedyczny1Lg.png';
import effectsPeelingMedyczny2Sm from '../assets/treatment/cosmetology/peelingMedyczny/effects/effectsPeelingMedyczny2Sm.png';
import effectsPeelingMedyczny2Lg from '../assets/treatment/cosmetology/peelingMedyczny/effects/effectsPeelingMedyczny2Lg.png';

// epilacja laserowa
import epilacjaLaserowaImage0 from '../assets/treatment/cosmetology/epilacjaLaserowa/epilacjaLaserowa0.jpg';
import effectsEpilacjaLaserowa0Sm from '../assets/treatment/cosmetology/epilacjaLaserowa/effects/effectsEpilacjaLaserowa0Sm.png';
import effectsEpilacjaLaserowa0Lg from '../assets/treatment/cosmetology/epilacjaLaserowa/effects/effectsEpilacjaLaserowa0Lg.png';
import effectsEpilacjaLaserowa1Sm from '../assets/treatment/cosmetology/epilacjaLaserowa/effects/effectsEpilacjaLaserowa1Sm.png';
import effectsEpilacjaLaserowa1Lg from '../assets/treatment/cosmetology/epilacjaLaserowa/effects/effectsEpilacjaLaserowa1Lg.png';
import effectsEpilacjaLaserowa2Sm from '../assets/treatment/cosmetology/epilacjaLaserowa/effects/effectsEpilacjaLaserowa2Sm.png';
import effectsEpilacjaLaserowa2Lg from '../assets/treatment/cosmetology/epilacjaLaserowa/effects/effectsEpilacjaLaserowa2Lg.png';

// masaż próżniowy
import masazProzniowyImage0 from '../assets/treatment/cosmetology/masazProzniowy/masazProzniowy0.jpg';

// ultradzwieki i fala radiowa
import ultradzwiekiIFalaRadiowaImage0 from '../assets/treatment/cosmetology/ultradzwiekiIFalaRadiowa/ultradzwiekiIFalaRadiowa0.jpg';
import effectsUltradzwiekiIFalaRadiowa0Lg from '../assets/treatment/cosmetology/ultradzwiekiIFalaRadiowa/effects/effectsUltradzwiekiIFalaRadiowa0Lg.png';
import effectsUltradzwiekiIFalaRadiowa0Sm from '../assets/treatment/cosmetology/ultradzwiekiIFalaRadiowa/effects/effectsUltradzwiekiIFalaRadiowa0Sm.png';
import effectsUltradzwiekiIFalaRadiowa1Lg from '../assets/treatment/cosmetology/ultradzwiekiIFalaRadiowa/effects/effectsUltradzwiekiIFalaRadiowa1Lg.png';
import effectsUltradzwiekiIFalaRadiowa1Sm from '../assets/treatment/cosmetology/ultradzwiekiIFalaRadiowa/effects/effectsUltradzwiekiIFalaRadiowa1Sm.png';


// FIZJOTERAPIA
// rehabilitacja
import rehabilitacjaImage0 from '../assets/treatment/physioterapy/rehabilitacja/rehabilitacja0.jpg';

// masaż
import masazImage0 from '../assets/treatment/physioterapy/masaz/masaz0.jpg';

// kinesiotaping
import kinesiotapingImage0 from '../assets/treatment/physioterapy/kinesiotaping/kinesiotaping0.jpg';

// masaż kobido
import masazKobidoImage0 from '../assets/treatment/physioterapy/masazKobido/masazKobido0.jpg';

// masaz natural face lifting
import masazNaturalFaceLiftingImage0 from '../assets/treatment/physioterapy/masazNaturalFaceLifting/masazNaturalFaceLifting0.jpg';

// CHIRURGIA PLASTYCZNA
// plastyka powiek
import plastykaPowiekImage0 from '../assets/treatment/cosmeticSurgery/plastykaPowiek/plastykaPowiek0.jpg';
import effectsPlastykaPowiekSm0 from '../assets/treatment/cosmeticSurgery/plastykaPowiek/effects/effectsPlastykaPowiekSm0.png';
import effectsPlastykaPowiekLg0 from '../assets/treatment/cosmeticSurgery/plastykaPowiek/effects/effectsPlastykaPowiekLg0.png';


// ALERGOLOGIA
// testy skórne
import testySkorneImage0 from '../assets/treatment/allergology/testySkorne/testySkorne0.jpg';

// platkowe testy kontaktowe
import platkoweTestyKontaktoweImage0 from '../assets/treatment/allergology/platkoweTestyKontaktowe/platkoweTestyKontaktowe0.jpg';

// odczulanie
import odczulanieImage0 from '../assets/treatment/allergology/odczulanie/odczulanie0.jpg';


// CHIRURGIA NACZYNIOWA
// skleroterapia
import skleroterapiaImage0 from '../assets/treatment/vascularSurgery/skleroterapia/skleroterapia0.jpg';

// laserowe usuwanie żylaków
import laseroweUsuwanieZylakowImage0 from '../assets/treatment/vascularSurgery/laseroweUsuwanieZylakow/laseroweUsuwanieZylakow0.jpg';


// KARDIOLOGIA
// echo serca
import echoSercaImage0 from '../assets/treatment/cardiology/echoSerca/echoSerca0.jpg';


// PSYCHIATRIA
// badanie ados-2
import badanieAdos20 from '../assets/treatment/psychiatry/badanieAdos2/badanieAdos20.jpg';

// -------------------------------------------------------------------------------------------------------------------------------------------------------


// DERMATOLOGIA
export const badanieZnamionBarwnikowych = {
    id: '477cd9a2-d88c-4d6e-ab91-4250e8f899f3',
    treatment: 'badanie-znamion-barwnikowych',
    title: 'Badanie znamion barwnikowych',
    description: 'Badanie znamion barwnikowych polega na poddaniu zmian skórnych analizie przez dermatologa. W naszej Klinice badanie można przeprowadzić za pomocą dermatoskopu lub wideodermatoskopu. Wideodermatoskopia to nowoczesna cyfrowa technologia służąca do analizy znamion barwnikowych skóry. Urządzenie służy także diagnozowaniu zmian chorobowych skóry, włosów i paznokci. Jest to zaawansowany system łączący optyczny układ wideokamery dermatoskopu z komputerową analizą obrazu za pomocą algebraicznych algorytmów.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/dermatology/badanie-znamion-barwnikowych',
    images: [
        {
            src: badanieZnamionBarwnikowychImage0,
            alt: 'Dermatolog bada skórę pacjenta za pomocą dermatoskopu',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/dermatolog-bada-skore-pacjenta-za-pomoca-dermatoskopu_8404842.htm'
        }
    ],
    specialization: [
        'dermatology'
    ],
    specialist: [
        'nina-wisniewska',
        'aldona-maciag',
        'katarzyna-mysliwiec-czajka',
        'paulina-tatara',
        'katarzyna-rychlik'
    ],
    career: [
        'dermatologist'
    ]
};

export const fototerapiaChorobSkory = {
    id: 'd507d007-d43b-4664-9309-5b882c6577b6',
    treatment: 'fototerapia-chorob-skory',
    title: 'Fototerapia chorób skóry',
    description: 'Fototerapia UV jest skuteczną i wygodną metodą leczenia chorób skórnych promieniowaniem ultrafioletowym o określonej terapeutycznej długości fali. W naszej klinice przeprowadzamy światłoterapię UVB 311nm oraz PUVA-bath, po uprzedniej konsultacji dermatologicznej w naszej Klinice. Lekarz ustala plan fototerapii i dawki naświetlań według aktualnych wytycznych Polskiego Towarzystwa Dermatologicznego.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/dermatology/fototerapia-chorob-skory',
    images: [
        {
            src: fototerapiaChorobSkoryImage0,
            alt: 'Pacjent w okularach ochronnych przygotowany do zabiegu',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/pacjent-w-okularach-ochronnych-lezacych-na-leczenie_8403195.htm'
        }
    ],
    specialization: [
        'dermatology'
    ],
    specialist: [
        'nina-wisniewska',
        'aldona-maciag',
        'katarzyna-mysliwiec-czajka',
        'paulina-tatara',
        'katarzyna-rychlik'
    ],
    career: [
        'dermatologist'
    ]
};

export const usuniecieZmianSkornychElektrochirurgiczne = {
    id: 'a5201e49-2a7b-4c4f-a383-f53fb26bd461',
    treatment: 'usuniecie-zmian-skornych-elektrochirurgiczne',
    title: 'Usunięcie zmian skórnych elektrochirurgiczne',
    description: 'Elektrochirurgia, inaczej elektrokoagulacja, to metoda chirurgiczna polegająca na wykorzystaniu przepływu prądu elektrycznego o różnej częstotliwości do usuwania, wycinania lub wypalania, wszelkich niepożądanych zmian skórnych. Zabiegi można podzielić na elektrokoagulację i cięcie, które wykonuje się w znieczuleniu miejscowym. Zabieg elektrokoagulacji wykonuje się za pomocą elektrod o różnym kształcie, odpowiednio dobranych w zależności od rodzaju zmiany.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/dermatology/usuniecie-zmian-skornych-elektrochirurgiczne',
    images: [
        {
            src: usuniecieZmianSkornychElektrochirurgiczneImage0,
            alt: 'Dermatolog usuwający zmianę skórną z ramienia pacjenta',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/dermatolog-usuwajacy-kret-z-ramienia-kobiety_8896360.htm'
        }
    ],
    specialization: [
        'dermatology'
    ],
    specialist: [
        'aldona-maciag',
        'katarzyna-mysliwiec-czajka',
        'paulina-tatara',
    ],
    career: [
        'dermatologist'
    ]
};

// MEDYCYNA ESTETYCZNA
export const modelowanieUst = {
    id: 'e38025e7-4e87-4141-a208-853f3dd7ed68',
    treatment: 'modelowanie-ust',
    title: 'Modelowanie ust',
    description: 'Modelowanie i powiększanie czerwieni wargowej z wykorzystaniem kwasu hialuronowego to wciąż jeden z bardziej popularnych zabiegów medycyny estetycznej. Podając preparat z kwasem hialuronowym w strategiczne miejsca jesteśmy w stanie nie tylko uwydatnić i wypełnić usta, ale także zniwelować asymetrię, zredukować dysproporcję między wargą górną a dolną oraz nawilżyć przesuszoną czerwień wargową. Kwas hialuronowy wykazuje bowiem właściwości higroskopijne, czyli wiążące wodę. Dzięki czemu usta natychmiast stają się pełniejsze, mocniej nawilżone i bardziej sprężyste.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/aesthetic-medicine/modelowanie-ust',
    images: [
        {
            src: modelowanieUstImage0,
            alt: 'Modelowanie ust',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/zamyka-up-medyczny-botox-zastrzyk-w-wargach-zabieg-na-twarz_8467340.htm'
        }
    ],
    effects: [
        {
            alt: 'Efekt zabiegu modelowania ust',
            description: 'Efekt zabiegu modelowania ust',
            original: {
                src: effectsModelowanieUst0Lg,
                width: 1550,
                height: 1550
            },
            thumbnail: {
                src: effectsModelowanieUst0Sm
            }
        },
        {
            alt: 'Efekt zabiegu modelowania ust',
            description: 'Efekt zabiegu modelowania ust',
            original: {
                src: effectsModelowanieUst1Lg,
                width: 800,
                height: 800
            },
            thumbnail: {
                src: effectsModelowanieUst1Sm
            }
        },
        {
            alt: 'Efekt zabiegu modelowania ust',
            description: 'Efekt zabiegu modelowania ust',
            original: {
                src: effectsModelowanieUst5Lg,
                width: 1600,
                height: 1600
            },
            thumbnail: {
                src: effectsModelowanieUst5Sm
            }
        },
        {
            alt: 'Efekt zabiegu modelowania ust',
            description: 'Efekt zabiegu modelowania ust',
            original: {
                src: effectsModelowanieUst2Lg,
                width: 1600,
                height: 1600
            },
            thumbnail: {
                src: effectsModelowanieUst2Sm
            }
        },
        {
            alt: 'Efekt zabiegu modelowania ust',
            description: 'Efekt zabiegu modelowania ust',
            original: {
                src: effectsModelowanieUst3Lg,
                width: 1600,
                height: 1600
            },
            thumbnail: {
                src: effectsModelowanieUst3Sm
            }
        },
        {
            alt: 'Efekt zabiegu modelowania ust',
            description: 'Efekt zabiegu modelowania ust',
            original: {
                src: effectsModelowanieUst4Lg,
                width: 1430,
                height: 1430
            },
            thumbnail: {
                src: effectsModelowanieUst4Sm
            }
        },
        {
            alt: 'Efekt zabiegu modelowania ust',
            description: 'Efekt zabiegu modelowania ust',
            original: {
                src: effectsModelowanieUst6Lg,
                width: 1024,
                height: 1024
            },
            thumbnail: {
                src: effectsModelowanieUst6Sm
            }
        },
        {
            alt: 'Efekt zabiegu modelowania ust',
            description: 'Efekt zabiegu modelowania ust',
            original: {
                src: effectsModelowanieUst7Lg,
                width: 1350,
                height: 1350
            },
            thumbnail: {
                src: effectsModelowanieUst7Sm
            }
        },
    ],
    specialization: [
        'aesthetic-medicine'
    ],
    specialist: [
        'klaudia-malottki',
        'paulina-tatara',
        'olga-lenartowicz-warakomska'
    ]
};

export const toksynaBotulinowa = {
    id: 'bf052d1e-0735-46aa-95c9-beedd15e7671',
    treatment: 'toksyna-botulinowa',
    title: 'Toksyna botulinowa',
    description: 'Toksyna botulinowa to jedna z najbardziej popularnych metod redukcji zmarszczek mimicznych, które pojawiają się w związku z powtarzającymi się skurczami mięśni twarzy. Zabieg pozwala szybko spłycić zmarszczki mimiczne i wygładzić skórę. Wstrzyknięcie małej dawki leku w wybrane mięśnie twarzy likwiduje problemy wywoływane przez kurczenie się mięśni takie jak zmarszczki mimiczne czy nadmierna kurczliwość.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/aesthetic-medicine/toksyna-botulinowa',
    images: [
        {
            src: toksynaBotulinowaImage0,
            alt: 'Toksyna botulinowa',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/pielegnacja-skory-zabieg-kosmetologii-ladnej-kobiety-w-szpitalu-odmladzanie-iniekcje-terapia-profesjonalna-opieka-zdrowotna-plastik-botoks-uroda_10214268.htm'
        }
    ],
    effects: [
        {
            alt: 'Efekt zabiegu toksyny botulinowej',
            description: 'Efekt zabiegu toksyny botulinowej',
            original: {
                src: effectsToksynaBotulinowa0Lg,
                width: 1000,
                height: 1000
            },
            thumbnail: {
                src: effectsToksynaBotulinowa0Sm
            }
        },
        {
            alt: 'Efekt zabiegu toksyny botulinowej',
            description: 'Efekt zabiegu toksyny botulinowej',
            original: {
                src: effectsToksynaBotulinowa1Lg,
                width: 480,
                height: 620
            },
            thumbnail: {
                src: effectsToksynaBotulinowa1Sm
            }
        },
        {
            alt: 'Efekt zabiegu toksyny botulinowej',
            description: 'Efekt zabiegu toksyny botulinowej',
            original: {
                src: effectsToksynaBotulinowa2Lg,
                width: 950,
                height: 1060
            },
            thumbnail: {
                src: effectsToksynaBotulinowa2Sm
            }
        },
    ],
    specialization: [
        'aesthetic-medicine'
    ],
    specialist: [
        'klaudia-malottki',
        'paulina-tatara',
        'olga-lenartowicz-warakomska'
    ]
};

export const stymulatoryTkankowe = {
    id: '41dbc2e0-5729-429d-aa89-744a67409318',
    treatment: 'stymulatory-tkankowe',
    title: 'Stymulatory tkankowe',
    description: 'Stymulatory tkankowe to substancje lub techniki stosowane w medycynie w celu pobudzenia wzrostu, regeneracji lub funkcjonowania tkanek. Mają one na celu wspomaganie procesów naprawczych organizmu i stymulowanie go do regeneracji uszkodzonych tkanek.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/aesthetic-medicine/stymulatory-tkankowe',
    images: [
        {
            src: stymulatoryTkankoweImage0,
            alt: 'Zabieg kolagenowy w okolicy ust',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/zblizenie-na-kobiete-podczas-zabiegu-wypelniania-ust_23668908.htm'
        }
    ],
    specialization: ['aesthetic-medicine'],
    specialist: [
        'klaudia-malottki',
        'paulina-tatara',
        'olga-lenartowicz-warakomska'
    ]
};

export const osoczeBogatoplytkowe = {
    id: '59af0d17-9044-4181-9822-af36bda65dc6',
    treatment: 'osocze-bogatoplytkowe',
    title: 'Osocze bogatopłytkowe',
    description: 'Osocze bogatopłytkowe uzyskuje się z krwi własnej pacjenta poprzez podwójne odwirowanie substancji, które stanowią elementy morfotyczne krwi. Podczas odwirowywania krwi pobranej bezpośrednio przed zabiegiem zostają oddzielone krwinki czerwone oraz białe od płytek krwi. Osocze bogatopłytkowe uzyskuje się z standardowego poboru krwi. Po odwirowaniu zyskuje się płynny składnik krwi o niezwykłych i pożądanych przez nas właściwościach. Aby wprowadzić go pod skórę pacjenta wykorzystuje się mezoterapię, czyli ostrzykiwanie za pomocą bardzo cienkich igieł. Najczęściej ostrzykiwaniu osoczem poddawane są policzki, linia żuchwy, czoło, powieki oraz okolice oczu. Jednak osocze bogatopłytkowe przynosi również świetne rezultaty w poprawie wyglądu skóry szyi i dekoltu, ramion i dłoni oraz owłosionej skóry głowy.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/aesthetic-medicine/osocze-bogatoplytkowe',
    images: [
        {
            src: osoczeBogatoplytkoweImage0,
            alt: 'Regeneracja skóry na czole pacjenta',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/kosmetyczka-robi-wypelniacz-wtryskowy-na-klientce-w-salonie_12780811.htm'
        }
    ],
    specialization: ['aesthetic-medicine'],
    specialist: [
        'klaudia-malottki',
        'olga-lenartowicz-warakomska'
    ]
};

export const laserFrakcyjny = {
    id: '6351da55-b909-441e-a2a9-23442405dacb',
    treatment: 'laser-frakcyjny',
    title: 'Laser frakcyjny',
    description: 'Laser frakcyjny jest idealnym i sprawdzonym rozwiązaniem znajdującym zastosowanie w wielu dziedzinach medycyny. Laser pozwala na osiągnięcie bardzo dobrych efektów zabiegowych w postaci likwidacji blizn potrądzikowych, poprawy jakości skóry i wytworzenia nowych struktur kolagenu.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/aesthetic-medicine/laser-frakcyjny',
    images: [
        {
            src: laserFrakcyjnyImage0,
            alt: 'Zabieg usuwania przebarwień na twarzy',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/pacjentka-coraz-procedura-podnoszenia-rf_8896411.htm'
        }
    ],
    effects: [
        {
            alt: 'Efekt zabiegu lasera frakcyjnego',
            description: 'Efekt zabiegu lasera frakcyjnego',
            original: {
                src: effectsLaserFrakcyjny0Lg,
                width: 1220,
                height: 1220
            },
            thumbnail: {
                src: effectsLaserFrakcyjny0Sm
            }
        },
        {
            alt: 'Efekt zabiegu lasera frakcyjnego',
            description: 'Efekt zabiegu lasera frakcyjnego',
            original: {
                src: effectsLaserFrakcyjny1Lg,
                width: 1220,
                height: 1220
            },
            thumbnail: {
                src: effectsLaserFrakcyjny1Sm
            }
        }
    ],
    specialization: [
        'aesthetic-medicine'
    ],
    specialist: [
        'paulina-sot',
        'klaudia-malottki',
        'paulina-tatara',
        'olga-lenartowicz-warakomska',
        'karolina-zegota'
    ]
};

export const niciRewitalizujaceILiftingujace = {
    id: 'fef39d52-5440-4dd6-a534-de6de4f61c99',
    treatment: 'nici-rewitalizujace-i-liftingujace',
    title: 'Nici rewitalizujące i liftingujące',
    description: 'Nici liftingujące służą do usuwania skutków starzenia grawitacyjnego. Zmiany na skórze spowodowane są utratą sprężystości, zmniejszeniem napięcia mięśni i przemieszczaniem się podskórnej tkanki tłuszczowej. Nici liftingujące są mniej kłopotliwą i inwazyjną alternatywą dla chirurgicznego liftingu.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/aesthetic-medicine/nici-rewitalizujace-i-liftingujace',
    images: [
        {
            src: niciRewitalizujaceILiftingujaceImage0,
            alt: 'Przygotowanie do zabiegu',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/kobieta-w-gabinecie-kosmetycznym-na-leczenie_12844715.htm'
        }
    ],
    specialization: [
        'aesthetic-medicine'
    ],
    specialist: [
        'klaudia-malottki'
    ]
};

export const mezoterapiaIglowa = {
    id: 'ddddb0c3-be44-4056-936a-64fb7bb06fc4',
    treatment: 'mezoterapia-iglowa',
    title: 'Mezoterapia igłowa',
    description: 'Mezoterapia igłowa to przede wszystkim głębokie nawilżenie skóry. Zabieg polega na bezpośrednim podaniu małych dawek substancji biologicznie czynnej śródskórnie lub podskórnie w miejsca, które chcemy poddać zabiegowi. Mezoterapia igłowa pobudza również mikrokrążenie, co stymuluje komórki do produkcji nowych włókien kolagenowych. W efekcie uzyskujemy odświeżoną, napiętą skórę.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/aesthetic-medicine/mezoterapia-iglowa',
    images: [
        {
            src: mezoterapiaIglowaImage0,
            alt: 'Mezoterapia igłowa',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/kobieta-poddawana-zabiegowi-preparacji-skory-glowy-pod-wysokim-katem_26388252.htm'
        }
    ],
    specialization: [
        'aesthetic-medicine'
    ],
    specialist: [
        'klaudia-malottki',
        'paulina-tatara',
        'olga-lenartowicz-warakomska'
    ]
};

export const zamykanieNaczynekILikwidacjaPrzebarwien = {
    id: '9708d32b-a67e-4fb7-bc3a-844400f7e766',
    treatment: 'zamykanie-naczynek-i-likwidacja-przebarwien',
    title: 'Zamykanie naczynek i likwidacja przebarwień',
    description: 'Platforma laserowa, którą posiadamy w naszej Klinice, to  produkt izraelskiej firmy Alma Lasers. Obsługuje różne typy zaawansowanych technologii laserowych i innych źródeł światła. Oferuje rozwiązanie na większość problemów estetycznych i dermatologicznych',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/aesthetic-medicine/zamykanie-naczynek-i-likwidacja-przebarwien',
    images: [
        {
            src: zamykanieNaczynekILikwidacjaPrzebarwienImage0,
            alt: 'Zamykanie naczynek i likwidacja przebarwień',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/widok-lekarza-kosmetologa-robi-procedure-przeciw-starzeniu-sie-w-biurze-kosmetologii-zadowolona-kobieta-w-jednorazowym-kapeluszu-lezac-na-kanapie-i-relaksujac-sie_18849917.htm'
        }
    ],
    effects: [
        {
            alt: 'Efekt zabiegu zamykania naczynek',
            description: 'Efekt zabiegu zamykania naczynek',
            original: {
                src: effectsZamykanieNaczynekILikwidacjaPrzebarwien0Lg,
                width: 732,
                height: 732
            },
            thumbnail: {
                src: effectsZamykanieNaczynekILikwidacjaPrzebarwien0Sm
            }
        },
        {
            alt: 'Efekt zabiegu zamykania naczynek',
            description: 'Efekt zabiegu zamykania naczynek',
            original: {
                src: effectsZamykanieNaczynekILikwidacjaPrzebarwien1Lg,
                width: 1240,
                height: 1240
            },
            thumbnail: {
                src: effectsZamykanieNaczynekILikwidacjaPrzebarwien1Sm
            }
        },
        {
            alt: 'Efekt zabiegu zamykania naczynek',
            description: 'Efekt zabiegu zamykania naczynek',
            original: {
                src: effectsZamykanieNaczynekILikwidacjaPrzebarwien2Lg,
                width: 1100,
                height: 1100
            },
            thumbnail: {
                src: effectsZamykanieNaczynekILikwidacjaPrzebarwien2Sm
            }
        }
    ],
    specialization: [
        'aesthetic-medicine'
    ],
    specialist: [
        'paulina-sot',
        'klaudia-malottki',
        'paulina-tatara',
        'olga-lenartowicz-warakomska',
        'karolina-zegota'
    ]
};

export const sonoQueenTechnologiaHifu = {
    id: '6dbc65da-d98d-448c-9a3c-184a2923e0db',
    treatment: 'sonoqueen-technologia-hifu',
    title: 'SonoQueen technologia HIFU',
    description: 'SonoQueen to nowoczesna technologia wykorzystująca moc HIFU. Najnowsza generacja technologii HIFU (High Intensity Focused Ultrasound) to sfokusowane ultradźwięki o wysokiej częstotliwości do bezinwazyjnego, niechirurgicznego liftingu twarzy i ciała.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/aesthetic-medicine/sonoqueen-technologia-hifu',
    images: [
        {
            src: sonoQueenTechnologiaHifuImage0,
            alt: 'Zabieg liftingu twarzy',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/kobieta-lezy-na-kanapie-w-procedurze-czyszczenia-twarzy_14481187.htm'
        }
    ],
    specialization: [
        'aesthetic-medicine'
    ],
    specialist: [
        'klaudia-malottki',
        'olga-lenartowicz-warakomska'
    ]
};


// KOSMETOLOGIA
export const peelingMedyczny = {
    id: '222d4fbe-a3a9-437f-bea8-9c161244a0a1',
    treatment: 'peeling-medyczny',
    title: 'Peeling medyczny',
    description: 'Peelingi chemiczne to kondycjonujące i rewitalizujące skórę zabiegi dermokosmetyczne. W zależności od zastosowanego preparatu zabiegi oferują różne efekty terapeutyczne; tj. oczyszczające i przeciwtrądzikowe, regenerujące i przeciwzmarszczkowe, rozjaśniające i redukujące przebarwienia.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/cosmetology/peeling-medyczny',
    images: [
        {
            src: peelingMedycznyImage0,
            alt: 'Kosmetolog nakładający peeling na twarz',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/kosmetyczka-pedzelkiem-naklada-biala-maseczke-nawilzajaca-na-twarz-mlodej-klientki-w-gabinecie-kosmetycznym-spa_13340680.htm'
        }
    ],
    effects: [
        {
            alt: 'Efekt zabiegu peelingu medycznego',
            description: 'Efekt zabiegu peelingu medycznego',
            original: {
                src: effectsPeelingMedyczny0Lg,
                width: 1917,
                height: 2560
            },
            thumbnail: {
                src: effectsPeelingMedyczny0Sm
            }
        },
        {
            alt: 'Efekt zabiegu peelingu medycznego',
            description: 'Efekt zabiegu peelingu medycznego',
            original: {
                src: effectsPeelingMedyczny2Lg,
                width: 2560,
                height: 1689
            },
            thumbnail: {
                src: effectsPeelingMedyczny2Sm
            }
        },
    ],
    specialization: [
        'cosmetology'
    ],
    specialist: [
        'paulina-sot',
        'karolina-zegota'
    ],
    career: [
        'cosmetologist'
    ]
};

export const epilacjaLaserowa = {
    id: 'ec1aafb7-54a5-42ae-9abc-fcaab3a549fc',
    treatment: 'epilacja-laserowa',
    title: 'Epilacja laserowa',
    description: 'Laser diodowy to produkt amerykańskiej firmy, która stworzyła pierwszą na świecie technologię laserowego usuwania owłosienia, do dziś chronioną patentem. Urządzenie jest owocem trwających kilkanaście lat doświadczeń i badań nad udoskonaleniem systemu lasera diodowego. Wykorzystano w nim wiele nowatorskich rozwiązań decydujących o wyjątkowej skuteczności, bezpieczeństwie i komforcie pacjenta.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/cosmetology/epilacja-laserowa',
    images: [
        {
            src: epilacjaLaserowaImage0,
            alt: 'Epilacja lasorowa',
            author: 'ITP S.A.',
            href: 'https://itpestetyka.pl'
        }
    ],
    effects: [
        {
            alt: 'Efekt zabiegu epilacji laserowej',
            description: 'Efekt zabiegu epilacji laserowej',
            original: {
                src: effectsEpilacjaLaserowa0Lg,
                width: 2560,
                height: 2560
            },
            thumbnail: {
                src: effectsEpilacjaLaserowa0Sm
            }
        },
        {
            alt: 'Efekt zabiegu epilacji laserowej',
            description: 'Efekt zabiegu epilacji laserowej',
            original: {
                src: effectsEpilacjaLaserowa1Lg,
                width: 1650,
                height: 1650
            },
            thumbnail: {
                src: effectsEpilacjaLaserowa1Sm
            }
        },
        {
            alt: 'Efekt zabiegu epilacji laserowej',
            description: 'Efekt zabiegu epilacji laserowej',
            original: {
                src: effectsEpilacjaLaserowa2Lg,
                width: 1700,
                height: 1700
            },
            thumbnail: {
                src: effectsEpilacjaLaserowa2Sm
            }
        }
    ],
    specialization: [
        'cosmetology'
    ],
    specialist: [
        'paulina-sot',
        'karolina-zegota'
    ],
    career: [
        'cosmetologist'
    ]
};

export const masazProzniowy = {
    id: '21151498-e8b5-4c95-9704-b41072b9c19a',
    treatment: 'masaz-prozniowy',
    title: 'Masaż próżniowy',
    description: 'Masaż próżniowy inspirowany jest intuicją profesora Jean-Claude\'a Guimberteau, specjalisty w dziedzinie chirurgii rekonstrukcyjnej, który poświęcił wiele lat na obserwację skóry w zakresie struktury tkanki łącznej. Guimberteau wykazał, że skóra musi być pojmowana jako żywa, ciągła materia, a tkanka podskórna składa się z sieci mikrowakuoli, które tworzą funkcjonalną jednostkę struktury tkanki. Ta nowa wizja całkowicie zastępuje tradycyjną koncepcję tkanki warstwowej, a tym samym narzuca potrzebę nowego sposobu leczenia skóry.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/cosmetology/masaz-prozniowy',
    images: [
        {
            src: masazProzniowyImage0,
            alt: 'Masaż próżniowy',
            author: 'ITP S.A.',
            href: 'https://itpestetyka.pl'
        }
    ],
    specialization: [
        'cosmetology'
    ],
    specialist: [
        'paulina-sot',
        'karolina-zegota'
    ],
    career: [
        'cosmetologist'
    ]
};

export const ultradzwiekiIFalaRadiowa = {
    id: '328355f4-cead-4e99-a89d-1fe1906e01c5',
    treatment: 'ultradzwieki-i-fala-radiowa',
    title: 'Ultradzwieki i fala radiowa',
    description: 'Ultradźwięki połączone z falą radiową to zabieg, który w bezpieczny sposób redukuje cellulit i tkankę tłuszczową, niweluje wiotkość skóry oraz modeluje twarz. Dzięki połączeniu dwóch technologii intensyfikujemy efekty terapeutyczne. Ultradźwięki selektywnie niszczą błony komórkowe adipocytów, czyli komórek tłuszczowych. Następnie fala radiowa przyśpiesza metabolizm usprawniając działanie układu limfatycznego oraz usuwanie uwolnionego tłuszczu z organizmu. Radiofrekwencja pobudza również fibroblasty co skutkuje ujędrnieniem skóry w okolicy zabiegowej.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/cosmetology/ultradzwieki-i-fala-radiowa',
    images: [
        {
            src: ultradzwiekiIFalaRadiowaImage0,
            alt: 'Zabieg redukujący celulit',
            author: 'ITP S.A.',
            href: 'https://itpestetyka.pl'
        }
    ],
    effects: [
        {
            alt: 'Efekt zabiegu redukcji rozstępów',
            description: 'Efekt zabiegu redukcji rozstępów',
            original: {
                src: effectsUltradzwiekiIFalaRadiowa0Lg,
                width: 1780,
                height: 1780
            },
            thumbnail: {
                src: effectsUltradzwiekiIFalaRadiowa0Sm
            }
        },
        {
            alt: 'Efekt zabiegu redukcji rozstępów',
            description: 'Efekt zabiegu redukcji rozstępów',
            original: {
                src: effectsUltradzwiekiIFalaRadiowa1Lg,
                width: 1300,
                height: 1600
            },
            thumbnail: {
                src: effectsUltradzwiekiIFalaRadiowa1Sm
            }
        }
    ],
    specialization: [
        'cosmetology'
    ],
    specialist: [
        'paulina-sot',
        'karolina-zegota'
    ],
    career: [
        'cosmetologist'
    ]
};

// FIZJOTERAPIA
export const rehabilitacja = {
    id: '05a5105b-d8cc-4198-b2a8-7a89491a691f',
    treatment: 'rehabilitacja',
    title: 'Rehabilitacja',
    description: 'Medycyna ortopedyczna według Cyriaxa jest kompleksowym systemem terapii oraz diagnostyki zaburzeń narządu ruchu. Opiera się na badaniu klinicznym, które za pomocą testów i wywiadu doprowadza fizjoterapeutę do postawienia odpowiedniej diagnozy uszkodzonej tkanki w układzie ruchu.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/physioterapy/rehabilitacja',
    images: [
        {
            src: rehabilitacjaImage0,
            alt: 'Fizjoterapeuta wykonujący rehabilitację nogi',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/masaz-medyczny-na-nodze-w-centrum-fizjoterapii_1492577.htm'
        },
    ],
    specialization: ['physioterapy'],
    specialist: [
        'milena-juzwiak'
    ]
};

export const masaz = {
    id: '96b1c738-fb80-4648-bc89-5e4cee1d44dc',
    treatment: 'masaz',
    title: 'Masaż',
    description: 'Zespół technik i chwytów oddziaływujących na cały organizm, zdrowie i kondycję. Polega na wykorzystaniu dotyku w celu wywołania uczucia odprężenia i dobrego samopoczucia. Masaż wykorzystywany jest w celu: rozluźnienia mięśni, złagodzenia sztywności, napięcia i bólu mięśni, zwiększenia elastyczności i wytrzymałości więzadeł, poprawy ruchomości stawów, wzmożenia zasilenia organów w tlen i substancje odżywcze odprężenia i przezwyciężenia stresu.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/physioterapy/masaz',
    images: [
        {
            src: masazImage0,
            alt: 'Odprężający masaż karku',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/kobieta-w-salonie-spa_8871855.htm'
        },
    ],
    specialization: ['physioterapy'],
    specialist: [
        'milena-juzwiak'
    ]
};

export const kinesiotaping = {
    id: 'eb42daf1-07f8-4a66-a47b-9613710fbf9e',
    treatment: 'kinesiotaping',
    title: 'Kinesiotaping',
    description: 'Kinesiotaping czyli plastrowanie dynamiczne. Technika fizjoterapeutyczna polegająca na naklejaniu na ciało zgodnie z określonymi zasadami specjalnych taśm. Powodują one regulację napięcia mięśniowego w miejscu aplikacji, zmniejszając ból, ograniczając nadwrażliwość na dotyk, zwiększając zakres ruchu i siłę mięśni oraz zmniejszyć obrzęki w miejscu aplikacji.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/physioterapy/kinesiotaping',
    images: [
        {
            src: kinesiotapingImage0,
            alt: 'Plastrowanie dynamiczne nogi',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/mloda-kobieta-w-stroju-sportowym-cwiczaca-cwiczenie-podczas-sesji-fizjoterapeutycznej_18843285.htm'
        }
    ],
    specialization: [
        'physioterapy'
    ],
    specialist: [
        'milena-juzwiak'
    ]
};

export const masazKobido = {
    id: '759e773a-0613-4658-adc5-0e84126b5a01',
    treatment: 'masaz-kobido',
    title: 'Masaż Kobido',
    description: 'Jest to masaż pochodzący z Japonii, inaczej nazywany niechirurgicznym liftingiem twarzy. W masażu tym intensywnej pracy zostają poddane okolice twarzy, szyi i dekoltu. Jego cel to głębokie odżywienie skóry, rozluźnienie mięśni i powięzi, pobudzenie skóry do produkcji kolagenu i elastyny oraz relaks.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/physioterapy/masaz-kobido',
    images: [
        {
            src: masazKobidoImage0,
            alt: 'Uśmiechnięta kobieta podczas masażu twarzy',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/usmiechnieta-mloda-kobieta-na-zabieg-na-twarz_14091394.htm'
        }
    ],
    specialization: [
        'physioterapy'
    ],
    specialist: [
        'karina-kwiatkowska'
    ]
};

export const masazNaturalFaceLifting = {
    id: 'e261051b-c641-4961-a4b5-b9d8c9b581e9',
    treatment: 'masaz-natural-face-lifting',
    title: 'Masaż Natural Face Lifting',
    description: 'Jest to masaż bazujący na technikach masażu Kobido, poszerzony o mocniejsze i głębsze techniki masażu twarzy oraz dodatkowo używa się w nim płytki GUA SHA, baniek próżniowych, a na koniec, w celu osiągnięcia jeszcze lepszego efektu, nakładana jest maseczka. Masaż ten został stworzony na potrzeby klientów, których twarz potrzebuje mocniejszego modelowania i liftingu.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/physioterapy/masaz-natural-face-lifting',
    images: [
        {
            src: masazNaturalFaceLiftingImage0,
            alt: 'Kobieta podczas masażu twarzy',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/piekna-kaukaska-kobieta-dostaje-anti-age-masaz-w-zdroju-salonie_5633721'
        }
    ],
    specialization: [
        'physioterapy'
    ],
    specialist: [
        'karina-kwiatkowska'
    ]
};

// CHIRURGIA PLASTYCZNA
export const plastykaPowiek = {
    id: 'e7f2520e-2e6e-4a08-bead-72ea02cd3842',
    treatment: 'plastyka-powiek',
    title: 'Plastyka powiek',
    description: 'Plastyka powiek, inaczej blefaroplastyka, to zabieg mający na celu usunięcie nadmiaru skóry wokół oczu. W zakres blefaroplastyki wchodzi zarówno korekta powieki górnej jak i dolnej. Plastyka powiek jest najskuteczniejszą metodą walki z twz. opadającą powieką. Wskazaniami do zabiegu prócz defektu estetycznego są również asymetria oczu, problemy z widocznością, przepukliny tłuszczowe a także wiotkość tkanki wokół oczu.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/cosmetic-surgery/plastyka-powiek',
    images: [
        {
            src: plastykaPowiekImage0,
            alt: 'Przygotowanie pacjenta do zabiegu plastyki powiek',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/lekarz-bada-twarz-pacjentki-do-leczenia-kosmetycznego_8896763.htm'
        }
    ],
    effects: [
        {
            alt: 'Efekt zabiegu plastyki powiek',
            description: 'Efekt zabiegu plastyki powiek',
            original: {
                src: effectsPlastykaPowiekLg0,
                width: 1665,
                height: 1424
            },
            thumbnail: {
                src: effectsPlastykaPowiekSm0
            }
        }
    ],
    specialization: [
        'cosmetic-surgery'
    ],
    specialist: [
        'konrad-kochan'
    ]
};


// ALERGOLOGIA
export const testySkorne = {
    id: '198ecbfc-6ee6-4d7f-9310-2a8476008187',
    treatment: 'testy-skorne',
    title: 'Testy skórne',
    description: 'Testy skórne są jedną z popularniejszej oraz prostszej metody diagnostycznej alergii. Największą zaletą testów skórnych jest ich krótki czas oczekiwania na wyniki. Badanie przeprowadza się na wewnętrznej części przedramienia lub skórze pleców. Przebieg testów polega na naniesieniu kropli różnych zawiesin z alergenami z zestawu przesiewowego zawierającego takie substancje uczulające jak rośliny wiatropylne, trawy, pleśnie, sierść zwierząt, jad owadów czy roztocza kurzu domowego.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/allergology/testy-skorne',
    images: [
        {
            src: testySkorneImage0,
            alt: 'Test reakcji alergicznej skóry na przedramieniu',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/test-reakcji-alergicznej-skory-na-ramieniu-osoby_19672574.htm'
        },
    ],
    specialization: [
        'allergology'
    ],
    specialist: [
        'tomasz-matuszewski'
    ]
};

export const platkoweTestyKontaktowe = {
    id: '90b88e0f-c08d-462f-8c18-91d9873be3bb',
    treatment: 'platkowe-testy-kontaktowe',
    title: 'Płatkowe testy kontaktowe',
    description: 'Płatkowe testy kontaktowe, znane również jako testy skórne płatkowe lub testy płatkowe na alergeny, są diagnostycznym narzędziem stosowanym w medycynie w celu identyfikacji alergii kontaktowych. Są one wykorzystywane do wykrywania uczuleń skórnych, które mogą powodować reakcje alergiczne, takie jak swędzenie, zaczerwienienie, wysypka, pokrzywka czy łuszczenie się skóry.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/allergology/platkowe-testy-kontaktowe',
    images: [
        {
            src: platkoweTestyKontaktoweImage0,
            alt: 'Płatkowy test kontaktowy skóry pleców',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/reakcja-alergiczna-skory-na-plecach-osoby_19672630.htm'
        },
    ],
    specialization: [
        'allergology'
    ],
    specialist: [
        'tomasz-matuszewski'
    ]
};

export const odczulanie = {
    id: 'f483fece-c103-4001-a468-287699d16f31',
    treatment: 'odczulanie',
    title: 'Odczulanie',
    description: 'Immunoterapia swoista, czyli odczulanie, to jedyna przyczynowa metoda leczenia alergii. Odczulanie ma na celu zmniejszyć wrażliwość organizmu na dany czynnik alergizujący, co w następstwie redukuje uciążliwe objawy alergii. Polega ono na podawaniu coraz to większych stężeń alergenu w szczepionce co stopniowo zmniejsza wrażliwość organizmu na hapteny uczulające. Dawka wyciągu alergennego wynosi od 5 do 20 μg podanego w iniekcji podskórnej.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/allergology/odczulanie',
    images: [
        {
            src: odczulanieImage0,
            alt: 'Odczulanie skóry na przedramieniu',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/test-reakcji-alergicznej-skory-na-ramieniu-osoby_19672578.htm'
        }
    ],
    specialization: [
        'allergology'
    ],
    specialist: [
        'tomasz-matuszewski'
    ]
};

// CHIRURGIA NACZYNIOWA
export const skleroterapia = {
    id: '45ee2498-633a-4e8e-8732-d1308a01cfe8',
    treatment: 'skleroterapia',
    title: 'Skleroterapia',
    description: 'Skleroterapia to nieinwazyjny zabieg zamykania rozszerzonych naczyń krwionośnych. Procedura polega na podaniu do problematycznej żyły substancji obliterującej, która powoduje jej zamknięcie. W porównaniu z leczeniem operacyjnym jest to metoda bezpieczną i zdecydowanie mniej bolesną. Zabieg skleroterapii poprzedzony jest dokładną oceną układu żył głównych oraz powierzchniowych przy pomocy badania ultrasonograficznego z dopplerem, które jest wykonywane w naszej Klinice.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/vascular-surgery/skleroterapia',
    images: [
        {
            src: skleroterapiaImage0,
            alt: 'Zabieg zamykania rozszerzonych naczyń krwionośnych',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/chirurg-robi-zastrzykowi-w-zenskiego-cialo-koncepcja-liposukcji_1624924.htm'
        }
    ],
    specialization: [
        'vascular-surgery'
    ],
    specialist: [
        'kamil-stepkowski'
    ]
};

export const laseroweUsuwanieZylakow = {
    id: '46e1a803-49cc-498f-90bd-f8d41b43efdb',
    treatment: 'laserowe-usuwanie-zylakow',
    title: 'Laserowe usuwanie żylaków',
    description: 'Żylaki kończyn dolnych są dziś jedną z najbardziej powszechnych chorób. Około 40% kobiet i 20% mężczyzn ma objawy w postaci: zmęczonych, ciężkich nóg oraz obrzęków nóg i żył widocznych przez skórę. Jej główne przyczyny to: wrodzona słabość tkanki łącznej, zmiany hormonalne, a także długotrwałe przebywanie w pozycji siedzącej lub stojącej.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/vascular-surgery/laserowe-usuwanie-zylakow',
    images: [
        {
            src: laseroweUsuwanieZylakowImage0,
            alt: 'Kobieta masująca nogę',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/zblizenie-dloni-masuje-nogi_10582355.htm'
        }
    ],
    specialization: [
        'vascular-surgery'
    ],
    specialist: [
        'kamil-stepkowski'
    ]
};

// KARDIOLOGIA
export const echoSerca = {
    id: '69d011cf-bd77-4910-95e2-6ba2234a0f21',
    treatment: 'echo-serca',
    title: 'Echo serca',
    description: 'Echo serca, czyli echokardiografia, to bezpieczne i nieinwazyjne badanie obrazowe serca, które wykorzystuje fale dźwiękowe o wysokiej częstotliwości do tworzenia obrazów struktur serca. Zabieg ten przeprowadza się za pomocą urządzenia zwanych echokardiografami, które emitują fale dźwiękowe przez specjalną sondę umieszczoną na klatce piersiowej pacjenta.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/cardiology/echo-serca',
    images: [
        {
            src: echoSercaImage0,
            alt: 'Pacjent w trakcie badania serca',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/czlowiek-z-elektroda-na-ciele_3140810.htm'
        }
    ],
    specialization: [
        'cardiology'
    ],
    specialist: [
        'michal-radzio'
    ]
};

// PSYCHIATRIA
export const badanieAdos2 = {
    id: '69d011cf-bd77-4910-95e2-6ba2234a0f21',
    treatment: 'badanie-ados-2',
    title: 'Badanie Ados-2',
    description: 'Badanie Ados-2 dla dzieci, młodzieży i osób dorosłych. Pomaga wykryć  spektrum autyzmu (Zespół Aspergera,  spektrum autyzmu) u dzieci od 12 miesiąca życia, ale także u młodzieży i u dorosłych.',
    info: 'Zdjęcie ukazuje efekt zabiegu u konkretnego pacjenta. Efekt zabiegu może się różnić w zależności od indywidualnych cech pacjneta, liczby powtórzeń zabiegu, stosowania się pacjenta do zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby przeprowadzającej zabieg.',
    path: '/treatment/psychiatry/badanie-ados-2',
    images: [
        {
            src: badanieAdos20,
            alt: 'Specjalista przeprowadza terapię z chłopcem',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/kobieta-robi-terapie-mowy-z-malym-blond-chlopcem_18683396.htm'
        }
    ],
    specialization: [
        'psychiatry'
    ],
    specialist: [
        'kaja-strojnowska-lapinska'
    ],
    career: [
        'psychiatrist'
    ]
};

export const treatment = [
    // DERMATOLOGIA
    { ...badanieZnamionBarwnikowych },
    { ...fototerapiaChorobSkory },
    { ...usuniecieZmianSkornychElektrochirurgiczne },

    // MEDYCYNA ESTETYCZNA
    { ...modelowanieUst },
    { ...toksynaBotulinowa },
    { ...stymulatoryTkankowe },
    { ...osoczeBogatoplytkowe },
    { ...laserFrakcyjny },
    { ...niciRewitalizujaceILiftingujace },
    { ...mezoterapiaIglowa },
    { ...zamykanieNaczynekILikwidacjaPrzebarwien },
    { ...sonoQueenTechnologiaHifu },

    // KOSMETOLOGIA
    { ...peelingMedyczny },
    { ...epilacjaLaserowa },
    { ...masazProzniowy },
    { ...ultradzwiekiIFalaRadiowa },

    // FIZJOTERAPIA
    { ...rehabilitacja },
    { ...masaz },
    { ...kinesiotaping },
    { ...masazKobido },
    { ...masazNaturalFaceLifting },

    // CHIRURGIA PLASTYCZNA
    { ...plastykaPowiek },

    // ALERGOLOGIA
    { ...testySkorne },
    { ...platkoweTestyKontaktowe },
    { ...odczulanie },

    // CHIRURGIA NACZYNIOWA
    { ...skleroterapia },
    { ...laseroweUsuwanieZylakow },

    // KARDIOLOGIA
    { ...echoSerca },

    // PSYCHIATRIA
    { ...badanieAdos2 }
];
