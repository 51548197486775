const FacebookSvg = ({
    width,
    height,
    color
}) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        shapeRendering='geometricPrecision'
        textRendering='geometricPrecision'
        imageRendering='optimizeQuality'
        fillRule='evenodd'
        clipRule='evenodd'
        viewBox='160.01 0 319.99 640'
        width={width}
        height={height}
        fill={color}
    >
        <path d='M380.001 120.001h99.993V0h-99.993c-77.186 0-139.986 62.8-139.986 139.986v60h-80.009V320h79.985v320h120.013V320h99.994l19.996-120.013h-119.99v-60.001c0-10.843 9.154-19.996 19.996-19.996v.012z' />
    </svg>
);

export default FacebookSvg;
