const ServicesSvg = ({
    width,
    height,
    color
}) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 99.75 122.88'
        width={width}
        height={height}
        fill={color}
    >
        <path d='M26.68,77.37A8.95,8.95,0,1,1,20.35,80a9,9,0,0,1,6.33-2.62Zm0,4.52a4.43,4.43,0,1,1-4.43,4.43,4.42,4.42,0,0,1,4.43-4.43Zm4.69-.27a6.65,6.65,0,1,0,1.94,4.7,6.61,6.61,0,0,0-1.94-4.7ZM35.27,0A8.95,8.95,0,1,1,29,2.62,8.93,8.93,0,0,1,35.27,0Zm0,4.52A4.43,4.43,0,1,1,30.85,9a4.43,4.43,0,0,1,4.42-4.43ZM40,4.25A6.65,6.65,0,1,0,41.91,9,6.64,6.64,0,0,0,40,4.25Zm27.3,53.54A8.95,8.95,0,1,1,61,60.41a8.93,8.93,0,0,1,6.32-2.62Zm0,4.52a4.43,4.43,0,1,1-4.42,4.43,4.43,4.43,0,0,1,4.42-4.43ZM72,62a6.65,6.65,0,1,0,1.94,4.7A6.62,6.62,0,0,0,72,62ZM5.39,36.85C1.07,34.12-.75,31.86.28,28A7.23,7.23,0,0,1,9.13,22.9L35.41,38l5.27,1.25a31.6,31.6,0,0,0,17.72.16l7-1.63L90.67,23.27a7.24,7.24,0,0,1,8.85,5.11c1,3.85-1.44,6.75-5.11,8.85l-27,15.48.06,1.49h-.19a12.57,12.57,0,0,0-4.58.88l-.21.08a12.55,12.55,0,0,0-4.06,2.72,12.74,12.74,0,0,0-2.64,3.86l-.09.21a12.54,12.54,0,0,0,6.58,16.29l.21.09a12.41,12.41,0,0,0,4.79,1,11.89,11.89,0,0,0,2.16-.19c1.26,13.28,2.6,26.63,2.6,38.68,0,6.23-13.25,7.37-14.5,0L52.71,82H47.06l-4.82,35.74c-.82,7-14.93,6.59-14.51,0L29,98.64a12.62,12.62,0,0,0,2.45-.74,12.45,12.45,0,0,0,3.84-2.51l.23-.21a12.38,12.38,0,0,0,2.62-3.86l.1-.21a12.45,12.45,0,0,0,1-4.79,12.29,12.29,0,0,0-1-4.8,12.46,12.46,0,0,0-2.71-4.06h0a12.63,12.63,0,0,0-3.85-2.62l-.21-.1c-.27-.11-.54-.21-.82-.3l1.5-22.24L5.39,36.85ZM49.9,4.59A14.56,14.56,0,1,1,35.53,21.48,12.45,12.45,0,0,0,43.91,18l.23-.21A12.55,12.55,0,0,0,46.76,14l.1-.21a12.63,12.63,0,0,0,1-4.79,12.32,12.32,0,0,0-.69-4.09,14.17,14.17,0,0,1,2.78-.27Z' />
    </svg>
);

export default ServicesSvg;
