import specialOffer1 from '../assets/specialOffer/specialOffer1.jpg';
import specialOffer2 from '../assets/specialOffer/specialOffer2.jpg';
import specialOffer3 from '../assets/specialOffer/specialOffer3.jpg';
import specialOffer4 from '../assets/specialOffer/specialOffer4.jpg';
import specialOffer5 from '../assets/specialOffer/specialOffer5.jpg';
import specialOffer6 from '../assets/specialOffer/specialOffer6.jpg';
import specialOffer7 from '../assets/specialOffer/specialOffer7.jpg';
import specialOffer8 from '../assets/specialOffer/specialOffer8.jpg';
import specialOffer9 from '../assets/specialOffer/specialOffer9.jpg';
import specialOffer10 from '../assets/specialOffer/specialOffer10.jpg';
import specialOffer11 from '../assets/specialOffer/specialOffer11.jpg';

export const article1 = {
    image: specialOffer1,
    title: 'Halloweenowy dzień z medycyną estetyczną',
    date: '25 października 2022',
    description1: 'Zapraszamy na zabiegi z zakresu medycyny estetycznej: botox, kwas hialuronowy, mezoterapia, stymulatory tkankowe, laser Alma Harmony, laser frakcyjny CO2, SonoQueen.',
    description2: 'Zapisz się już dziś i razem z nami pozbądź się niechcących zmarszczek. 💀🎃👻',
    description3: '❗️Uwaga: Zostały ostatnie wolne miejsca na 31 października u Dr Olgi Lenartowicz. 🧡',
    description4: '',
    description5: ''
};

export const article2 = {
    image: specialOffer2,
    title: 'Konsultacje dietetyczne',
    date: '13 grudnia 2022',
    description1: 'Problem z wagą? 🥗 Nie wiesz jak odchudzić się bez efektu jojo? A może chcesz zbilansować swoją dietę lub uzupełnić niedobory? 🤨 👉🏻 Dobrym pomysłem będzie udanie się do dietetyka klinicznego. 👩🏼‍⚕️',
    description2: 'Tworzenie posiłków 🥙 wydaje nam się prostą codzienną czynnością, jednak czasem potrzebujemy fachowej pomocy. 🤷🏼‍♀️ Dietetyk kliniczny pokaże, że odchudzanie nie musi być męczarnią, a zdrowe odżywianie może być proste i smaczne. 😍 A nasze nieudane próby zrzucenia paru kilo pójdą w zapomnienie! 🤭💚',
    description3: 'Najczęstsze problemy z jakimi możesz zgłosić się do naszej Kliniki: uzupełnienie niedoborów organizmu, chęć odchudzania się w racjonalny sposób, potrzeba wdrożenia specjalistycznej diety ze względu na choroby współistniejące.',
    description4: 'Niezależnie czy borykasz się z nadwagą, niedoborami lub po prostu zależy Ci na zdrowszym odżywianiu nasz dietetyk mgr Joanna Ojrzeńska udzieli wyczerpującej konsultacji dietetycznej oraz ułoży dietę.',
    description5: ''
};

export const article3 = {
    image: specialOffer3,
    title: 'Życzenia świąteczne',
    date: '23 grudnia 2022',
    description1: 'Zdrowych, spokojnych Świąt Bożego Narodzenia, spędzonych w gronie najbliższych 🎄🎁⛄️ życzy cały zespół Kliniki dr Nina Wiśniewska. 🎅🏻',
    description2: '',
    description3: '',
    description4: '',
    description5: ''
};

export const article4 = {
    image: specialOffer4,
    title: 'Witamy w Nowym Roku',
    date: '2 stycznia 2023',
    description1: 'Wkroczyliśmy w nowy rok. 🥂 Dla wielu z nas oznacza to noworoczne postanowienia. Zakładamy co chcielibyśmy osiągnąć lub zmienić w tym nadchodzącym 2023. 🤔',
    description2: 'Dla niektórych będzie to przeczytanie 100 książek 📚, dla kogoś innego ukończenie kursu gotowania 👨🏻‍🍳, a dla części z nas być może to chęć uporania się z niedoskonałościami skórnymi. 💆🏻‍♀️',
    description3: 'W związku z powyższym informujemy o możliwości przeprowadzenia profesjonalnej konsultacji kosmetologicznej w naszej Klinice. 🌸',
    description4: 'Na wizycie omówimy aktualny stan cery, porozmawiamy o obecnej pielęgnacji i wyeliminujemy jej ewentualne błędy. Zasugerujemy o co warto poszerzyć domową rutynę a czego lepiej w niej unikać. Ułożymy także spersonalizowany plan zabiegowy dostosowany do indywidualnych potrzeb i oczekiwań. 🤗',
    description5: ''
};

export const article5 = {
    image: specialOffer5,
    title: 'Walentynkowa oferta specjalna',
    date: '1 lutego 2023',
    description1: 'Podaj hasło: VALENTYNKI! ❤️ Zapraszamy do nas na tydzień zakochanych! Tylko od 13 do 17 lutego trwa wyjątkowa promocja na wybrane zabiegi z naszej oferty z okazji walentynek. To idealna sposobność by sprawić swojej drugiej połówce voucher prezentowy na wymarzony zabieg. 🥰',
    description2: 'Zapraszamy do kontaktu z nami po szczegóły promocji. Zapisy ruszają dzisiaj! Nie zapomnij podać hasła! Liczba miejsc ograniczona. 👨‍👩‍👧‍👦',
    description3: 'Zabiegi objęte promocją: 🔶 Nucleofill 850 zł zamiast 1100 zł, 🔶 Plasmoo 899 zł zamiast 1099 zł, 🔶 Pakiet 4 masaży 269 zł zamiast 319 zł, 🔶 Pakiet jędrny brzuch 5 zabiegów Accent +5 zabiegów Icoone (okolica brzucha) 2199 zł zamiast 2999 zł.',
    description4: '',
    description5: '',
};

export const article6 = {
    image: specialOffer6,
    title: 'Twoje remedium na ciężkość nóg',
    date: '26 kwietnia 2023',
    description1: 'Najczęściej obrzęki pojawiają się w kończynach dolnych po wielogodzinnej pracy w pozycji siedzącej 🪑 lub stojącej, w trakcie ciąży 🤰🏼czy podczas długich podróży. ✈️ Oprócz widocznej opuchlizny towarzyszy nam uczucie ciężkości nóg, ograniczonej ruchomości, a także ogólne zmęczenie i rozdrażnienie.',
    description2: 'Skąd w ogóle pojawiają się obrzęki łydek? 🤔 Limfa oraz krew z dystalnych części kończyny dolnej musi pokonać bowiem siły grawitacji. Układ limfatyczny oraz żyły wykorzystują więc tzw. “pompę mięśniową” czyli siłę pracy naszych mięśni nóg. W momencie kiedy mięśnie są w stanie spoczynku np przy pracy przy biurku, nie kurczą się. Odpływ limfy zostaje zahamowany. 🔄 Wynikiem tego są właśnie obrzęki nóg. Oczywiście na powstawanie obrzęków mają wpływ także dodatkowe obciążenia organizmu jak np.: zaburzenia układu krążenia, choroby tarczycy, cukrzyca, stany po mastektomii.',
    description3: 'Co zatem zrobić, aby pozbyć się zastojów limfatycznych? 🤔 Możemy wspomóc krążenie poprzez drenaż limfatyczny.',
    description4: '🤎 Drenaż limfatyczny, często nazywany masażem limfatycznym, to specjalny rodzaj masażu niwelujący uciążliwe obrzęki. Powolne ruchy z technik głaskania, rozcierania i ugniatania o określonej sile przepychają limfę i udrażniają węzły chłonne. Taki masaż kompresyjny nie tylko likwiduje obrzęki ale także poprawia metabolizm i przyśpiesza usuwanie toksyn z organizmu. 😌',
    description5: 'Ręczny masaż limfatyczny w naszej Klinice wykonuje nasza fizjoterapeutka mgr Milena Juźwiak. 👩🏻‍⚕️',
};

export const article7 = {
    image: specialOffer7,
    title: 'Poznaj nici liftingujące',
    date: '29 czerwca 2023',
    description1: 'Nasza skóra z wiekiem traci swoją pierwotną sprężystość i jędrność. 😔 Ulega towarzyszącej nam grawitacji i upływowi czasu. W efekcie rysy twarzy ulegają zatarciu, a nadmiar wiotkiej skóry zaczyna być widoczny. I tu z pomocą przychodzą nam nici liftingujące. 💙',
    description2: 'Nici to małoinwazyjna, nowoczesna i skuteczna metoda niwelowania oznak starzenia. 🤩 Tworzą one naturalne „rusztowanie”, które unosi i podtrzymuje tkanki. Pierwsze efekty pozabiegowe widoczne są więc od razu po zabiegu. Ponadto nici indukują proces przebudowy skóry poprzez pobudzenie fibroblastów. ⏱ Po około 10-14 dniach rozpoczyna się proces syntezy nowych włókien kolagenu i elastyny, dzięki czemu skóra zagęszcza się, wzmacnia i napina. 💙',
    description3: '👉🏻 Nici liftingujące są także mniej kłopotliwą i inwazyjną alternatywą dla chirurgicznego liftingu. Wyróżniamy dwa rodzaje nici: rewitalizujące i liftingujące właściwe (haczykowate). Nasza Klinika oferuje oba typy nici. 😌',
    description4: 'Wskazania: 🔶 utrata napięcia i jędrności skóry, 🔶 zmarszczki na twarzy, szyi i dekolcie, 🔶 opadające policzki, kąciki ust ikąciki powieki, 🔶 worki pod oczami, 🔶 asymetria brwi, 🔶 asymetria owalu twarzy, 🔶 linie marionetki, 🔶 podwójny podbródek, 🔶 zmiany zanikowe na dłoniach',
    description5: 'Osiągane efekty pozabiegowe są kwestią indywidualną, uzyskiwane w zależności od uwarunkowań genetycznych, stanu wyjściowego skóry oraz trybu życia prowadzonego przez pacjenta.',
};

export const article8 = {
    image: specialOffer8,
    title: 'Lipcowa oferta specjalna',
    date: '3 lipca 2023',
    description1: 'Lato w pełni. 🌞 Czas urlopów, festiwali, wesel… Chcemy zaprezentować się z jak najlepszej strony. Nic dziwnego! A my chcemy Wam to ułatwić! ☺️Z tego powodu przygotowaliśmy dla Was specjalną ofertę promocyjną. 🌺',
    description2: '💕 Zabiegi wyszczuplające na ciało wykorzystujące masaż próżniowy -30% od cen pakietowych oraz pojedynczych zabiegów.',
    description3: '💕 Zabiegi modelujące z użyciem ultradźwięków i fali radiowej do pakietu 5 zabiegów - 3 zabiegi GRATIS.',
    description4: '💕 Do każdego spersonalizowanego zabiegu na twarz - komputerowa analiza stanu skóry GRATIS.',
    description5: 'Promocja trwa tylko w lipcu! 🌺 Śpiesz się! Zadbaj o kondycję twarzy i ciała. 🤗',
};

export const article9 = {
    image: specialOffer9,
    title: 'Specjalna oferta na lasery',
    date: '2 Października 2023',
    description1: 'Wraz z początkiem października 🍂 przygotowaliśmy dla Was specjalną ofertę na serię zabiegów z użyciem specjalistycznych laserów na twarz.',
    description2: '🍁 Zamykanie naczynek, Redukcja przebarwień: Pierwszy zabieg - 800zł, Drugi zabieg - 700zł, Trzeci zabieg - 600zł.',
    description3: '☔ Redukcja blizn, Resurfacing skóry: PIerwszy zabieg - 1000zł, Drugi zabieg - 900zł, Trzeci zabieg - 800zł.',
    description4: '🌻 Promocja dotyczy serii 3 zabiegów na twarz, wykonywanych w odstępach co 4 tyg.',
    description5: '',
};

export const article10 = {
    image: specialOffer10,
    title: 'Jesienna oferta',
    date: '20 Września 2024',
    description1: '☔ Laser frakcyjny + Mezoterapia Skinbooster 1500zł zamiast 2000zł (redukcja blizn, redukcja zmarszczek, odżywianie skóry, nawilżanie skóry).',
    description2: '🍂 Laser frakcyjny, Laser naczyniowy, Laser na przebarwienia -20% za zabieg (cała twarz).',
    description3: '🍁 Depilacja laserowa - Pakiet całe nogi (4 zabiegi) + Gratis - Pakiet pachy (4 zabiegi) 2000zł zamiast 2700zł.',
    description4: 'Regulamin❗Oferta ważna do 31.10.2024❗Aby skorzystać z oferty należy dokonać płatności z góry❗Zabiegi wykonywane są w wybrane dni przez wybranych specjalistów.',
    description5: '',
};

export const article11 = {
    image: specialOffer11,
    title: 'Black month',
    date: '14 Listopada 2024',
    description1: '🖤 Laser frakcyjny -20% (redukcja blizn, redukcja zmarszczek, resurfacing skóry) *Rabat naliczany jest przy zabiegach wykonanych na całą twarz / szyję / dekolt 🔷 Laser naczyniowy / na przebarwienia -20% *Rabat naliczany jest przy zabiegach wykonanych na całą twarz / szyję / dekolt 🔷 Epilacja laserowa -20% 🔷 Zabieg oczyszczająco - biostymulujący na twarz *300zł zamiast 350zł (Najniższa cena z ostatnich 30 dni - 350 zł) 🔷 Peelingi medyczne -20% 🔷 Masaż próżniowy -30% 🔷 Ultradźwięki + Fala radiowa -50%',
    description2: 'Regulamin❗Oferta ważna od 18.11.2024 do 31.12.2024❗Aby skorzystać z oferty należy dokonać płatności z góry❗Zabiegi wykonywane są w wybrane dni przez wybranych specjalistów.',
    description3: '',
    description4: '',
    description5: '',
};

export const specialOffer = [
    { ...article11 },
    { ...article10 },
    { ...article9 },
    { ...article8 },
    { ...article7 },
    { ...article6 },
    { ...article5 },
    { ...article4 },
    { ...article3 },
    { ...article2 },
    { ...article1 }
];
