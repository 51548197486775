import careerDermatologist0 from '../assets/career/dermatologist/careerDermatologist0.jpg';
import careerPsychiatrist0 from '../assets/career/psychiatrist/careerPsychiatrist0.jpg';
import servicesUrology0 from '../assets/services/urology/servicesUrology0.jpg';
import careerHematologist0 from '../assets/career/hematologist/careerHematologist0.jpg';
import careerReceptionist0 from '../assets/career/receptionist/careerReceptionist0.jpg';
import careerCosmetologist0 from '../assets/career/cosmetologist/careerCosmetologist0.jpg';

export const receptionist = {
    id: '71e87692-51b5-46fa-ae7a-9d4c29844dd3',
    career: 'receptionist',
    specialization: null,
    title: 'Recepcjonistka',
    status: "Pilna",
    location: 'Wyszków, mazowieckie',
    date: '30 stycznia 2024',
    agreement: 'umowa o pracę, umowa zlecenie',
    position: 'młodszy specjalista (junior)',
    type: 'pełny etat',
    place: 'praca stacjonarna',
    path: '/career/receptionist',
    details: [
        {
            designation: 'location',
            label: 'Wyszków, mazowieckie',
            value: 'adres',
        },
        {
            designation: 'date',
            label: '30 stycznia 2024',
            value: 'data publikacji',
        },
        {
            designation: 'agreement',
            label: 'umowa o pracę, umowa zlecenie',
            value: 'forma zatrudnienia',
        },
        {
            designation: 'position',
            label: 'młodszy specjalista (junior)',
            value: 'doświadczenie',
        },
        {
            designation: 'type',
            label: 'pełny etat',
            value: 'wymiar czasu',
        },
        {
            designation: 'place',
            label: 'praca stacjonarna',
            value: 'miejsce pracy',
        },
    ],
    image: careerReceptionist0,
    images: [
        {
            src: careerReceptionist0,
            alt: 'Rozmowa w recepcji',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/szczesliwa-kobieta-rozmawia-z-recepcjonista-podczas-przybycia-do-spa_26628259.htm'
        }
    ],
    duties: [
        'praca w recepcji placówki',
        'przyjmowanie płatności',
        'udzielania informacji o ofercie usług medycznych',
        'życzliwa i profesjonalna obsługa pacjenta',
        'nadzór nad przepływem dokumentacji pacjenta',
        'dbanie o wizerunek Kliniki',
        'współpraca z lekarzami oraz pracownikami medycznymi.'
    ],
    requirements: [
        'wykształcenie średnie',
        'dyspozycyjność',
        'doświadczenie w obsłudze klienta',
        'umiejątność obsługi programów komputerowych (Excel, Outlook)',
        'wiedza z zakresu medycyny estetycznej i kosmetologii',
        'umiejętność planowania i dobrej organizacji pracy',
        'komunikatywność',
        'umiejętność pracy w zespole'
    ],
    offer: [
        'stabilne warunki pracy',
        'przyjazna atmosfera w pracy',
        'możliwość współpracy z lekarzami oraz pracownikami medycznymi',
        'możliwość pracy w znanej i dynamicznie rozwijającej się firmie'
    ]
};

export const hematologist = {
    id: '1cc7d38f-bc83-4e4b-a3b3-71bf00a34ecf',
    career: 'hematologist',
    specialization: null,
    title: 'Hematolog',
    status: null,
    location: 'Wyszków, mazowieckie',
    date: '8 marca 2024',
    agreement: 'umowa B2B',
    position: 'specjalista (mid/regular)',
    type: 'pełny etat',
    place: 'praca stacjonarna',
    path: '/career/hematologist',
    details: [
        {
            designation: 'location',
            label: 'Wyszków, mazowieckie',
            value: 'adres',
        },
        {
            designation: 'date',
            label: '8 marca 2024',
            value: 'data publikacji',
        },
        {
            designation: 'agreement',
            label: 'umowa B2B',
            value: 'forma zatrudnienia',
        },
        {
            designation: 'position',
            label: 'specjalista (mid/regular)',
            value: 'doświadczenie',
        },
        {
            designation: 'type',
            label: 'pełny etat',
            value: 'wymiar czasu',
        },
        {
            designation: 'place',
            label: 'praca stacjonarna',
            value: 'miejsce pracy',
        },
    ],
    image: careerHematologist0,
    images: [
        {
            src: careerHematologist0,
            alt: 'Lekarz pobierający próbkę krwi od pacjenta',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/przyjazny-szpitalny-flebotomista-pobierajacy-probke-krwi-od-pacjenta-w-laboratorium-przygotowanie-do-badania-krwi-przez-lekarke-mundur-medyczny-na-stole-w-bialym-jasnym-pokoju_28001948.htm'
        }
    ],
    duties: [
        'dbałość o wysokie standardy medyczne w placówce',
        'profesjonalna opieka medyczna nad Pacjentem',
        'przestrzeganie procedur',
        'świadczenia usług w zakresie hematologii',
        'sumienne prowadzenie dokumentacji medycznej'
    ],
    requirements: [
        'umiejętności interpersonalne i nastawienie na jakość obsługi Pacjenta',
        'profesjonalizm i wysoka kultura osobista',
        'dyplom ukończenia studiów i aktualne PWZ',
        'ukończona specjalizacja (lub w trakcie specjalizacji)'
    ],
    offer: [
        'umowa B2B',
        'nowoczesny sprzęt medyczny oraz rozwiązania informatyczne w obsłudze pacjenta',
        'możliwość samorealizacji, doskonalenia swoich umiejętności i poszerzania kompetencji zawodowych',
        'wsparcie kadry zarządzającej oraz wykwalifikowanego personelu',
        'praca w prężnie rozwijającej się firmie'
    ]
};

export const dermatologist = {
    id: '69223216-8d29-47e6-b475-e45c9eb86801',
    career: 'dermatologist',
    specialization: 'dermatology',
    title: 'Dermatolog',
    status: null,
    location: 'Wyszków, mazowieckie',
    date: '8 marca 2024',
    agreement: 'umowa B2B',
    position: 'specjalista (mid/regular)',
    type: 'pełny etat',
    place: 'praca stacjonarna',
    path: '/career/dermatologist',
    details: [
        {
            designation: 'location',
            label: 'Wyszków, mazowieckie',
            value: 'adres',
        },
        {
            designation: 'date',
            label: '8 marca 2024',
            value: 'data publikacji',
        },
        {
            designation: 'agreement',
            label: 'umowa B2B',
            value: 'forma zatrudnienia',
        },
        {
            designation: 'position',
            label: 'specjalista (mid/regular)',
            value: 'doświadczenie',
        },
        {
            designation: 'type',
            label: 'pełny etat',
            value: 'wymiar czasu',
        },
        {
            designation: 'place',
            label: 'praca stacjonarna',
            value: 'miejsce pracy',
        },
    ],
    image: careerDermatologist0,
    images: [
        {
            src: careerDermatologist0,
            alt: 'Dermatolog badający skórę pacjentki',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/dermatolog-badajacy-skore-pacjentki_8404627.htm'
        }
    ],
    duties: [
        'dbałość o wysokie standardy medyczne w placówce',
        'profesjonalna opieka medyczna nad pacjentem',
        'przestrzeganie procedur',
        'świadczenia usług w zakresie dermatologii',
        'sumienne prowadzenie dokumentacji medycznej'
    ],
    requirements: [
        'umiejętności interpersonalne i nastawienie na jakość obsługi pacjenta',
        'profesjonalizm i wysoka kultura osobista',
        'dyplom ukończenia studiów i aktualne PWZ',
        'ukończona specjalizacja (lub w trakcie specjalizacji)'
    ],
    offer: [
        'umowa B2B',
        'nowoczesny sprzęt medyczny oraz rozwiązania informatyczne w obsłudze pacjenta',
        'możliwość samorealizacji, doskonalenia swoich umiejętności i poszerzania kompetencji zawodowych',
        'wsparcie kadry zarządzającej oraz wykwalifikowanego personelu',
        'praca w prężnie rozwijającej się firmie'
    ]
};

export const urologist = {
    id: 'c3324f77-5734-403b-a615-c187e485a897',
    career: 'urologist',
    specialization: null,
    title: 'Urolog',
    status: null,
    location: 'Wyszków, mazowieckie',
    date: '8 marca 2024',
    agreement: 'umowa B2B',
    position: 'specjalista (mid/regular)',
    type: 'pełny etat',
    place: 'praca stacjonarna',
    path: '/career/urologist',
    details: [
        {
            designation: 'location',
            label: 'Wyszków, mazowieckie',
            value: 'adres',
        },
        {
            designation: 'date',
            label: '8 marca 2024',
            value: 'data publikacji',
        },
        {
            designation: 'agreement',
            label: 'umowa B2B',
            value: 'forma zatrudnienia',
        },
        {
            designation: 'position',
            label: 'specjalista (mid/regular)',
            value: 'doświadczenie',
        },
        {
            designation: 'type',
            label: 'pełny etat',
            value: 'wymiar czasu',
        },
        {
            designation: 'place',
            label: 'praca stacjonarna',
            value: 'miejsce pracy',
        },
    ],
    image: servicesUrology0,
    images: [
        {
            src: servicesUrology0,
            alt: 'Konsultacja w gabinecie urologicznym',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/widok-profilu-mlodego-dietetyka-mezczyzny-rozmawiajacego-z-pacjentem-w-jego-gabinecie-w-klinice-zdrowia-i-spa_28031068.htm'
        }
    ],
    duties: [
        'dbałość o wysokie standardy medyczne w placówce',
        'profesjonalna opieka medyczna nad pacjentem',
        'przestrzeganie procedur',
        'świadczenia usług w zakresie urologii',
        'sumienne prowadzenie dokumentacji medycznej'
    ],
    requirements: [
        'umiejętności interpersonalne i nastawienie na jakość obsługi pacjenta',
        'profesjonalizm i wysoka kultura osobista',
        'dyplom ukończenia studiów i aktualne PWZ',
        'ukończona specjalizacja (lub w trakcie specjalizacji)'
    ],
    offer: [
        'umowa B2B',
        'nowoczesny sprzęt medyczny oraz rozwiązania informatyczne w obsłudze pacjenta',
        'możliwość samorealizacji, doskonalenia swoich umiejętności i poszerzania kompetencji zawodowych',
        'wsparcie kadry zarządzającej oraz wykwalifikowanego personelu',
        'praca w prężnie rozwijającej się firmie'
    ]
};

export const psychiatrist = {
    id: '35aee9ed-81dd-49bb-a2b9-baaa233fbdcb',
    career: 'psychiatrist',
    specialization: 'psychiatry',
    title: 'Psychiatra osób dorosłych',
    status: null,
    location: 'Wyszków, mazowieckie',
    date: '8 marca 2024',
    agreement: 'umowa B2B',
    position: 'specjalista (mid/regular)',
    type: 'pełny etat',
    place: 'praca stacjonarna',
    path: '/career/psychiatrist',
    details: [
        {
            designation: 'location',
            label: 'Wyszków, mazowieckie',
            value: 'adres',
        },
        {
            designation: 'date',
            label: '8 marca 2024',
            value: 'data publikacji',
        },
        {
            designation: 'agreement',
            label: 'umowa B2B',
            value: 'forma zatrudnienia',
        },
        {
            designation: 'position',
            label: 'specjalista (mid/regular)',
            value: 'doświadczenie',
        },
        {
            designation: 'type',
            label: 'pełny etat',
            value: 'wymiar czasu',
        },
        {
            designation: 'place',
            label: 'praca stacjonarna',
            value: 'miejsce pracy',
        },
    ],
    image: careerPsychiatrist0,
    images: [
        {
            src: careerPsychiatrist0,
            alt: 'Dziewczyna rozmawia z terapeutą',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/widok-z-przodu-mloda-dziewczyna-rozmawia-z-terapeuta_37516532.htm'
        }
    ],
    duties: [
        'dbałość o wysokie standardy medyczne w placówce',
        'profesjonalna opieka medyczna nad pacjentem',
        'przestrzeganie procedur',
        'świadczenia usług w zakresie psychiatrii',
        'sumienne prowadzenie dokumentacji medycznej'
    ],
    requirements: [
        'umiejętności interpersonalne i nastawienie na jakość obsługi pacjenta',
        'profesjonalizm i wysoka kultura osobista',
        'dyplom ukończenia studiów i aktualne PWZ',
        'ukończona specjalizacja (lub w trakcie specjalizacji)'
    ],
    offer: [
        'umowa B2B',
        'nowoczesne rozwiązania informatyczne w obsłudze pacjenta',
        'wsparcie kadry zarządzającej oraz wykwalifikowanego personelu',
        'praca w prężnie rozwijającej się firmie'
    ]
};

export const cosmetologist = {
    id: 'e6e10e51-d1eb-4db5-80c9-18caf8047d41',
    career: 'cosmetologist',
    specialization: 'cosmetology',
    title: 'Kosmetolog',
    status: "Wstrzymana",
    location: 'Wyszków, mazowieckie',
    date: '4 stycznia 2024',
    agreement: 'umowa o pracę, umowa zlecenie',
    position: 'specjalista (mid/regular)',
    type: 'pełny etat',
    place: 'praca stacjonarna',
    path: '/career/cosmetologist',
    image: careerCosmetologist0,
    details: [
        {
            designation: 'location',
            label: 'Wyszków, mazowieckie',
            value: 'adres',
        },
        {
            designation: 'date',
            label: '4 stycznia 2024',
            value: 'data publikacji',
        },
        {
            designation: 'agreement',
            label: 'umowa o pracę, umowa zlecenie',
            value: 'forma zatrudnienia',
        },
        {
            designation: 'position',
            label: 'specjalista (mid/regular)',
            value: 'doświadczenie',
        },
        {
            designation: 'type',
            label: 'pełny etat',
            value: 'wymiar czasu',
        },
        {
            designation: 'place',
            label: 'praca stacjonarna',
            value: 'miejsce pracy',
        },
    ],
    images: [
        {
            src: careerCosmetologist0,
            alt: 'Lekarz kosmetolog i pacjentka',
            author: 'Freepik',
            href: 'https://pl.freepik.com/darmowe-zdjecie/lekarz-kosmetolog-i-pacjentka_26090687.htm'
        }
    ],
    duties: [
        'wykonywanie zabiegów kosmetologicznych',
        'konsultacje z ustaleniem indywidualnego planu zabiegowego',
        'prowadzenie dokumentacji pacjenta',
        'dobór odpowiednich zabiegów do problemu danego pacjenta',
        'obsługa pacjenta na najwyższym poziomie'
    ],
    requirements: [
        'wykształcenie wyższe, kierunek kosmetologia',
        'mile widziane doświadczenie na podobnym stanowisku',
        'umiejętność wykonywania zabiegów kosmetologicznych',
        'umiejętność doboru odpowiedniego zabiegu do potrzeb pacjenta',
        'wysoka kultura osobista',
        'umiejętności komunikacyjne i interpersonalne'
    ],
    offer: [
        'stabilne warunki pracy',
        'elastyczny grafik',
        'przyjazna atmosfera pracy',
        'możliwość rozwoju i doskonalenia swoich umiejętności',
        'możliwość współpracy z lekarzami',
        'praca na nowoczesnych i profesjonalnych markach i urządzeniach',
        'możliwość pracy w znanej i dynamicznie rozwijającej się firmie'
    ]
};

export const career = [
    // { ...receptionist },
    { ...hematologist },
    { ...dermatologist },
    { ...urologist },
    { ...psychiatrist },
    // { ...cosmetologist }
];

export const application = {
    recruitment: [
        {
            label: 'Zbieranie i selekcja aplikacji',
            description: `Najpierw sprawdzimy Twoją aplikację.`,
        },
        {
            label: 'Spotkanie rekrutacyjne',
            description: 'Następnie spotkasz się z jednym z naszych rekruterów, aby lepiej się poznać.',
        },
        {
            label: 'Końcowa rozmowa kwalifikacyjna',
            description: `Jeśli wszystko pójdzie dobrze, zostaniesz zaproszony na końcową rozmowę kwalifikacyjną.`,
        },
        {
            label: 'Formalności',
            description: `W ostatnim kroku przeprowadzimy Cię przez formalności i weryfikację dokumentów, a także upewnimy się, że wszystko jest gotowe, aby bezproblemowo zacząć współpracę.`,
        }
    ]
};
