import clinic1Thumbnail from '../assets/clinic/clinic1Thumbnail.jpg';
import clinic1Original from '../assets/clinic/clinic1Original.jpg';
import clinic2Thumbnail from '../assets/clinic/clinic2Thumbnail.jpg';
import clinic2Original from '../assets/clinic/clinic2Original.jpg';
import clinic3Thumbnail from '../assets/clinic/clinic3Thumbnail.jpg';
import clinic3Original from '../assets/clinic/clinic3Original.jpg';
import clinic4Thumbnail from '../assets/clinic/clinic4Thumbnail.jpg';
import clinic4Original from '../assets/clinic/clinic4Original.jpg';
import clinic5Original from '../assets/clinic/clinic5Original.jpg';
import clinic5Thumbnail from '../assets/clinic/clinic5Thumbnail.jpg';
import clinic6Original from '../assets/clinic/clinic6Original.jpg';
import clinic6Thumbnail from '../assets/clinic/clinic6Thumbnail.jpg';

export const clinic = {
    pictures: [
        {
            designation: 'clinic1',
            author: 'Karol Dądera',
            original: {
                src: clinic1Original,
                width: 2560,
                height: 1660
            },
            thumbnail: {
                src: clinic1Thumbnail
            }
        },
        {
            designation: 'clinic2',
            author: 'Karol Dądera',
            original: {
                src: clinic2Original,
                width: 2560,
                height: 1660
            },
            thumbnail: {
                src: clinic2Thumbnail
            }
        },
        {
            designation: 'clinic3',
            author: 'Karol Dądera',
            original: {
                src: clinic3Original,
                width: 2560,
                height: 1660
            },
            thumbnail: {
                src: clinic3Thumbnail
            }
        },
        {
            designation: 'clinic4',
            author: 'Karol Dądera',
            original: {
                src: clinic4Original,
                width: 2560,
                height: 1660
            },
            thumbnail: {
                src: clinic4Thumbnail
            }
        },
        {
            designation: 'clinic5',
            author: 'Karol Dądera',
            original: {
                src: clinic5Original,
                width: 2560,
                height: 1660
            },
            thumbnail: {
                src: clinic5Thumbnail
            }
        },
        {
            designation: 'clinic6',
            author: 'Karol Dądera',
            original: {
                src: clinic6Original,
                width: 2560,
                height: 1660
            },
            thumbnail: {
                src: clinic6Thumbnail
            }
        }
    ],
    statistics: [
        {
            title: 6,
            subtitle:'Ponad 6 lat działalności',
            suffix: '+'
        },
        {
            title: 8125,
            subtitle: '8125 wykonanych zabiegów w 2023 roku',
            suffix: ''
        },
        {
            title: 99,
            subtitle: '99% zadowolonych klientów',
            suffix: '%'
        }
    ]
};
